<template>
  <div class="screen">
    <div class="screen__description">
      <div class="screen__header">
        <h1>Find<span>Me</span></h1>
        <h3>View the exact location of your friends<br/>or family members on the map</h3>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './styles.scss';
</style>